<template>
  <div>
    <el-row style="padding-left: 10px">
      <el-button type="primary" @click="projectAddOpen">添加项目参数</el-button>
    </el-row>
    <el-row style="margin-top:15px;padding-left: 10px">
      <el-table
        :data="proceedsProjectArray"
        border
        :row-style="{height: '38px'}"
        :cell-style="{padding: '0'}"
        :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
        max-height="700"
        style="width: 99%">
        <el-table-column #default="scope" label="项目名称" align="center" show-overflow-tooltip>
          {{scope.row.projectsName}}
          <el-badge type="primary" value="系统默认" v-if="scope.row.isDefault === 1"/>
        </el-table-column>
        <el-table-column prop="projectsType" label="项目类型" align="center"></el-table-column>
        <el-table-column label="是否激活" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="isChange(scope.row)"
                v-model="scope.row.isValid"
                :active-value="1"
                :inactive-value="0"
                :disabled="scope.row.isDefault === 1"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="营收项目" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="isChange(scope.row)"
                v-model="scope.row.isIncome"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="线上订单收款" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="isChange(scope.row)"
                v-model="scope.row.isOnline"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="订单收款" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="isChange(scope.row)"
                v-model="scope.row.isOrder"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="追加收款" align="center">
          <template slot-scope="scope">
            <el-switch
                @change="isChange(scope.row)"
                v-model="scope.row.isAdd"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="是否系统默认参数" v-if="isSuperAdmin" align="center" #default="scope">
          <el-switch
              @change="isChange(scope.row)"
              v-model="scope.row.isDefault"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="success" size="mini" v-if="scope.row.isDefault !== 1" @click="projectsAddOpen(scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <projects-add v-if="aState" :state="aState" :projectsType="projectsType" @close="projectsAddClose"></projects-add>
    <projects-edit v-if="eState" :state="eState" :projects="projects" @close="projectsEditClose"></projects-edit>
  </div>
</template>

<script>
import projectsAdd from "@/pages/parameter/projects/projects-add"
import projectsEdit from "@/pages/parameter/projects/projects-edit"
export default {
  name: "proceeds-project-list",
  components: {
    projectsAdd,
    projectsEdit
  },
  created() {
    this.pageInit()
  },
  data() {
    return {
      proceedsProjectArray: [],
      total: 0,
      page: 1,
      limit: 100,
      aState: false,
      eState: false,
      projectsType: this.$projectsType.proceeds,
      projects: {},
      tenantCrop: localStorage.getItem("tenantCrop"),
      isSuperAdmin: localStorage.getItem("tenantCrop") === '2a31c23a-c178-441614928053489'
    }
  },
  methods: {
    pageInit() {
      this.queryProceedsProjectList()
    },
    // 查询收款项目
    queryProceedsProjectList() {
      this.$selectUtils.queryProjectsList(this.$projectsType.proceeds, this.page, this.limit).then(response => {
        this.proceedsProjectArray = response.data.data.list
        this.total = response.data.data.total
      })
    },
    isChange(data) {
      this.$axios({
        method: "POST",
        url: "/projects/updateProject",
        params: {
          id: data.id,
          isValid: data.isValid,
          isIncome: data.isIncome,
          isOnline: data.isOnline,
          isOrder: data.isOrder,
          isAdd: data.isAdd,
          isDefault: data.isDefault,
          tenantCrop: localStorage.getItem("tenantCrop")
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? "修改成功" :response.data.msg,
          type: flag? 'success' :'error',
          duration: 1000,
        })
        this.queryProceedsProjectList()
      })
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryProceedsProjectList();
    },
    handleCurrentChange(val) {
      this.page =val
      this.queryProceedsProjectList()
    },
    projectAddOpen() {
      this.aState = true
    },
    projectsAddClose() {
      this.aState = false
      this.queryProceedsProjectList()
    },
    projectsAddOpen(val) {
      this.eState = true
      this.projects = val
    },
    projectsEditClose() {
      this.eState = false
      this.queryProceedsProjectList()
    }
  }
}
</script>

<style scoped>

</style>