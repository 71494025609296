<template>
  <el-dialog
      :title="title"
      width="30%"
      :visible.sync="states"
      :modal-append-to-body="false"
      @close="close">
    <el-form label-width="100px" :model="form" :rules="rules" ref="form">
      <el-row>
          <el-col :span="21">
            <el-form-item label="项目名称" prop="projectsName">
              <el-input v-model="form.projectsName" placeholder="请填写项目名称"></el-input>
            </el-form-item>
          </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="projectAdd">提 交</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "projects-add",
  props:{
    projectsType: {
      type: String,
      required: true,
    },
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      states: this.state,
      title: "添加"+this.projectsType,
      form: {
        projectsName: ""
      },
      rules: {
        projectsName: [{required: true, message: '请填写项目名称', trigger: 'blur'}],
      },
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    projectAdd() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$axios({
            method: "POST",
            url: "/projects/addProjects",
            params: {
              projectsType: this.projectsType,
              ...this.form,
              tenantCrop: localStorage.getItem("tenantCrop")
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? '项目添加成功': response.data.msg,
              type: flag ? 'success':'error',
              duration: 1000,
            });
            if (flag) {
              this.close();
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>