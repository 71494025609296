<template>
  <el-dialog
      title="项目修改"
      width="30%"
      :visible.sync="states"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="100px" :model="form" :rules="rules" ref="form">
      <el-row>
        <el-col>
          <el-col :span="21">
            <el-form-item label="项目名称" prop="projectsName">
              <el-input v-model="form.projectsName" placeholder="请填写项目名称"></el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="projectEdit">提 交</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "projects-edit",
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    projects: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      states: this.state,
      form: {
        id: this.projects.id,
        projectsName: this.projects.projectsName,
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      rules: {
        projectsName: [{required: true, message: '请填写项目名称', trigger: 'blur'}],
      },
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    projectEdit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$axios({
            method: "POST",
            url: "/projects/updateProject",
            params: this.form
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? '项目修改成功': response.data.msg,
              type: flag ? 'success':'error',
              duration: 1000,
            });
            if (flag) {
              this.close();
            }
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>